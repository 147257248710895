<template>
  <sdDrawer
      ref="SubStatusAddRef"
      :form="form"
      title="SubStatus aanmaken"
      type="submit"
      btnText="Toevoegen"
      submitBtnText="Opslaan"
  >
    <FormValidationWrap>
      <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" @finish="CreateSubStatus">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="Naam" name="name">
              <a-input v-model:value="form.name" placeholder=""/>
            </a-form-item>
            <a-form-item label="Kleur" name="color">
              <a-input
                  :style="{ height: '55px' }"
                  name="color"
                  type="color"
                  v-model:value="form.color"
              />
            </a-form-item>
            <a-form-item label="Hoofdstatus" name="parentStatus">
            <a-select v-model:value="form.parentStatus"
                      size="large" class="sDash_fullwidth-select"
                      :allowClear="true">
              <a-select-option name="type" v-for="(item,index) in this.parentStatusses"
                               :key="index" :value="item.status">
                {{ item.displayName }}
              </a-select-option>
            </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button size="large" class="btn-signin" type="primary" html-type="submit"> Opslaan</a-button>
        </div>
      </a-form>
    </FormValidationWrap>
  </sdDrawer>
</template>
<script>
import {computed, defineComponent, reactive, ref} from 'vue';
import {FormValidationWrap} from "../styled";
import {useStore} from "vuex";

const SubStatusAdd = defineComponent({
  name: 'SubStatusAdd',
  components: {
    FormValidationWrap,
  },
  setup() {
    const SubStatusAddRef = ref()
    const formRef = ref();
    const {dispatch, state} = useStore();
    const parentStatusses = computed(() => state.substatus.parentStatusses);
    const form = reactive({
      name: null,
    });
    const rules = {
      name: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      color: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      parentStatus: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
    };
    const closeDrawer = () => {
      SubStatusAddRef.value.onClose();
      formRef.value.resetFields();
      dispatch('getSubStatusses')
    }
    const CreateSubStatus = () => {
      dispatch('createSubStatus', {value:form,close:closeDrawer});
    };
    return {
      form,
      rules,
      CreateSubStatus,
      SubStatusAddRef,
      formRef,
      parentStatusses,
    };
  },
});

export default SubStatusAdd;
</script>
